import React, { useState, useEffect } from "react";
import "./TermsOfService.css";
import { colors } from "../../utils/colors";
import ReactMarkdown from "react-markdown";
import termsOfServiceMarkdown from "./TermsOfService.md";

function TermsOfService() {
	const [tosText, setTosText] = useState("");

	useEffect(() => {
		fetch(termsOfServiceMarkdown)
			.then((res) => res.text())
			.then((text) => setTosText(text));
	});

	return (
		<div className="container">
			<div className="plain-text" style={{ color: colors.squadOrange }}>
				<p align="center" style={{ fontSize: "40px", fontWeight: 500 }}>
					terms of service{" "}
				</p>
				<ReactMarkdown children={tosText} />
			</div>
		</div>
	);
}
export default TermsOfService;
