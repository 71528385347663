import React from "react";
import { useParams } from "react-router-dom";
// import logo from '../assets/logo.svg';
import '../App.css';
import { useEffect } from 'react'

/* eslint-disable */
export default function RedirectProfile(){
    const param = useParams()

    let storeLink = 'https://play.google.com/store/apps/details?id=com.sqdsocial.squad&pli=1'
    let redirectTo = 'squad://profile/' + param.id + '/true' //prod
    // let redirectTo = 'exp://u.expo.dev/update/b6328441-3540-4cef-9304-b488fea3be73/--/profile/' + param.id + '/true' //dev

    
    // let redirectTo = 'exp://128.0.0.1:8081/--/profile/' + param.id + '/true' //local

    if(navigator.platform === "iPhone"){
        storeLink = 'https://apps.apple.com/br/app/squad-social/id1591656974?l=en-GB'
    }
 

    useEffect(() => {
  
        // var url = "https://apps.apple.com/br/app/squad-social/id1591656974?l=en-GB";
        // var open = window.open(url);
        // if (open == null || typeof(open)=='undefined')
        //     alert("Turn off your pop-up blocker!\n\nWe try to open the following url:\n"+url);
        

        setTimeout(function () {  window.location.href = redirectTo; }, 5);
        // window.open(redirectTo);
        // setTimeout(function () { window.location = 'exp://192.168.0.112:8081/--/profile/U2FsdGVkX1-Q5YEVMruHwhAvK9oprf1XiU9yj7gLJUWR7m7nzEGedLXqiy+R05XD/true'; }, 5);
        
        // setTimeout(function () { window.location.href = 'https://apps.apple.com/br/app/squad-social/id1591656974?l=en-GB'; }, 5);

        // window.location = "https://apps.apple.com/br/app/squad-social/id1591656974?l=en-GB";
        // window.location = "Squad://";
        // setTimeout(function() {
        //     alert('It seems that your device does not support our feature.');
        //   }, 1000);
          
        //   document.write('<iframe style="border:none; width:1px; height:1px;" src="https://apps.apple.com/br/app/squad-social/id1591656974?l=en-GB://"></iframe>');  
    }, [])
    return(
        <header className="App-header" style={{backgroundColor: 'white'}}>
            <a
            className="App-link"
            //href="https://apps.apple.com/br/app/squad-social/id1591656974?l=en-GB"
            href={storeLink}
            target="_blank"
            rel="noopener noreferrer"
            style={{color : 'black', marginBottom:25}}
            >
            Download the app
            </a>
            <img src={logo} className="App-logo" alt="logo" />

            {/* <script type='text/javascript'>
                $('select.windowOpen').change(function(){
                    var url = $(this).val();

                    var open = window.open(url);
                    if (open == null || typeof(open)=='undefined')
                        alert("Turn off your pop-up blocker!\n\nWe try to open the following url:\n"+url);
                });
            </script> */}
            {/* <div>
                <Routes>
                    <Route path="/:id" element={<Component2/>} />
                </Routes>
            </div> */}
      </header>
    )
}
