import React from "react";
import PropTypes from "prop-types";
import { colors } from "../utils/colors";

const TextButtom = ({
	text,
	height,
	onClick,
	backgroundColor,
	textColor,
	textSize,
}) => {
	return (
		<button
			onClick={onClick}
			style={{
				border: "none",
				borderRadius: 40,
				height: `${height}px`,
				background: backgroundColor,
				color: textColor,
				padding: "0 35px",
				fontSize: `${textSize}px`,
			}}
		>
			{text}
		</button>
	);
};

TextButtom.propTypes = {
	height: PropTypes.number.isRequired,
	text: PropTypes.string.isRequired,
	textColor: PropTypes.string,
	textSize: PropTypes.number,
	onClick: PropTypes.func.isRequired,
};

TextButtom.defaultProps = {
	textColor: colors.primary,
	textSize: 16,
};

export default TextButtom;
