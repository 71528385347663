import React from "react";
import { useParams } from "react-router-dom";
import logo from '../assets/logo.svg';
import '../App.css';
import { useEffect } from 'react'

/* eslint-disable */
export default function RedirectGuide(){
    const param = useParams()

    let storeLink = 'https://play.google.com/store/apps/details?id=com.sqdsocial.squad&pli=1'
    let redirectTo = 'squad://guide/' + param.id + '/true' //prod
    // let redirectTo = 'squad://question/' + param.id + '/true' //local

    if(navigator.platform === "iPhone"){
        storeLink = 'https://apps.apple.com/br/app/squad-social/id1591656974?l=en-GB'
    }
 

    useEffect(() => {
        setTimeout(function () {  window.location.href = redirectTo; }, 5);
    }, [])
    return(
        <header className="App-header" style={{backgroundColor: 'white'}}>
            <a
            className="App-link"
            //href="https://apps.apple.com/br/app/squad-social/id1591656974?l=en-GB"
            href={storeLink}
            target="_blank"
            rel="noopener noreferrer"
            style={{color : 'black', marginBottom:25}}
            >
            Download the app
            </a>
            <text
            className="App-link"
            //href="https://apps.apple.com/br/app/squad-social/id1591656974?l=en-GB"
            rel="noopener noreferrer"
            style={{ fontSize:5, color : 'black', marginBottom:25, marginHorizontal:20}}
            >
            {redirectTo}
            </text>
            {/* <p style={{color : 'black', marginBottom:25}}>{params}</p> */}
            <img src={logo} className="App-logo" alt="logo" />
      </header>
    )
}