import React from "react";
import { ReactComponent as InstagramLogo } from "../assets/instagram.svg";

const InstagramButton = ({ instagramUsername, height, width, fill }) => {
	const redirectToInstagram = () => {
		const instagramUrl = `https://www.instagram.com/${instagramUsername}`;
		window.open(instagramUrl, "_blank");
	};

	return (
		<button
			onClick={redirectToInstagram}
			style={{
				border: "none",
				background: "transparent",
				cursor: "pointer",
			}}
		>
			<InstagramLogo style={{ width: width, height: height, fill: fill }} />
		</button>
	);
};

export default InstagramButton;
