import React, { useState, useEffect } from "react";
import logo from "../assets/logo.svg";
import InstagramButton from "./InstagramButtom";
import TikTokButton from "./TikTokButtom";
import { ReactComponent as ListButtom } from "../assets/List.svg";
import { colors } from "../utils/colors";
import TextButtom from "./TextButtom";
import { useNavigate } from "react-router-dom";
import ListModal from "./Modals/ListModal";
import { ReactComponent as InstagramLogo } from "../assets/instagram.svg";

function Header() {
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [showModal, setShowModal] = useState(false);
	const navigate = useNavigate();

	const handleButtonClick = () => {
		setShowModal(true);
	};

	const handleCloseModal = () => {
		setShowModal(false);
	};

	const handleClick = () => {
		navigate("/termsofservice");
	};

	const onItemClick = (index) => {
		if (index === 0) {
			const instagramUrl = `https://www.instagram.com/sqdsocial`;
			window.open(instagramUrl, "_blank");
		} else if (index === 1) {
			navigate("/termsofservice");
		}
		setShowModal(false);
	};

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth < 800) setShowModal(false);
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<div className="header">
			{windowWidth < 800 ? (
				<>
					<div className="side-buttoms">
						<button
							onClick={handleButtonClick}
							style={{
								border: "none",
								background: "transparent",
								cursor: "pointer",
							}}
						>
							<ListButtom
								style={{
									width: "40px",
									height: "40px",
								}}
							/>
						</button>
						{showModal && (
							<ListModal
								onItemClick={onItemClick}
								onClose={handleCloseModal}
								items={[
									<InstagramLogo
										style={{
											height: "5vmin",
											width: "5vmin",
											fill: colors.squadOrange,
										}}
									/>,
									<div
										style={{
											border: "none",
											borderRadius: 40,
											height: "60px",
											background: colors.squadBlue,
											color: colors.onPrimary,
											padding: "0 35px",
											fontSize: "16px",
											display: "flex",
											alignItems: "center",
										}}
									>
										terms of service
									</div>,
								]}
							/>
						)}
					</div>
					<button className="logo-button" onClick={() => navigate("/")}>
						<img src={logo} className="App-logo-static" alt="logo" />
					</button>
					<div className="side-buttoms" />
				</>
			) : (
				<>
					<div className="side-buttoms" />
					<button className="logo-button" onClick={() => navigate("/")}>
						<img src={logo} className="App-logo-static" alt="logo" />
					</button>
					<div className="side-buttoms">
						<TikTokButton
							username="sqdsocial"
							height={"3vmin"}
							width={"3vmin"}
							fill={colors.squadOrange}
						/>
						<InstagramButton
							instagramUsername="sqdsocial"
							height={"3vmin"}
							width={"3vmin"}
							fill={colors.squadOrange}
						/>
						<TextButtom
							text={"terms of service"}
							height={60}
							backgroundColor={colors.squadBlue}
							textColor={colors.onPrimary}
							textSize={16}
							onClick={handleClick}
						/>
					</div>
				</>
			)}
		</div>
	);
}
export default Header;
