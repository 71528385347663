import React, { useState, useEffect } from "react";
import { colors } from "../utils/colors";
import { useNavigate } from "react-router-dom";
import { ReactComponent as GooglePlay } from "../assets/google-play.svg";
import { ReactComponent as AppStore } from "../assets/app-store.svg";

function Footer() {
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const navigate = useNavigate();
	const urlIOS = "https://apps.apple.com/us/app/squad-social/id1591656974";
	const urlAndroid =
		"https://play.google.com/store/apps/details?id=com.sqdsocial.squad";

	const handleClick = () => {
		navigate("/termsofservice");
	};

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<>
			{/* <div className="footer" style={{ background: colors.secondaryBackground }}> */}
			{windowWidth > 800 && (
				<div
					className="footer"
					style={{ background: colors.secondaryBackground }}
				>
					<span style={{ color: colors.squadOrange, fontSize: "5vh" }}>
						Squad
					</span>
					<div style={{ flexDirection: "column" }}>
						<button onClick={handleClick}>Terms of Service</button>
						<br />
						<button
							onClick={() =>
								(window.location.href = "mailto:hey@sqdsocial.com")
							}
						>
							hey@sqdsocial.com
						</button>
					</div>
					<div style={{ flexDirection: "column" }}>
						<button onClick={() => window.open(urlAndroid, "_blank")}>
							<GooglePlay style={{ height: "100px", width: "13vw" }} />
						</button>
						<button onClick={() => window.open(urlIOS, "_blank")}>
							<AppStore
								style={{
									height: "100px",
									width: "13vw",
								}}
							/>
						</button>
					</div>
				</div>
			)}
			{windowWidth < 800 && (
				<div style={{ display: "flex", flexDirection: "column" }}>
					<div
						className="footer"
						style={{ background: colors.secondaryBackground }}
					>
						<button onClick={() => window.open(urlAndroid, "_blank")}>
							<GooglePlay style={{ height: "100px", width: "40vw" }} />
						</button>
						<button onClick={() => window.open(urlIOS, "_blank")}>
							<AppStore
								style={{
									height: "100px",
									width: "40vw",
								}}
							/>
						</button>
					</div>
						<button style={{ fontSize: "16px" }} onClick={handleClick}>
							Terms of Service
						</button>
				</div>
			)}
			{/* </div> */}
		</>
	);
}
export default Footer;
