import React from "react";
import "./Home.css";
import { colors } from "../../utils/colors";

function Home() {
	return (
		<div className="container">
			<span className="title" style={{ color: colors.squadOrange }}>
				<span>
					You’ve got <i>questions.</i>
				</span>
				<br />
				<span>
					Your Squad’s got <i>answers.</i>
				</span>
			</span>
		</div>
	);
}
export default Home;
