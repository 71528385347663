export const colors = {
	primary: "rgb(0, 0, 0)",
	onPrimary: "rgb(255, 255, 255)",
	primaryContainer: "rgb(0, 0, 0)",
	onPrimaryContainer: "rgb(255, 255, 255)",
	secondary: "rgb(116, 86, 95)",
	onSecondary: "rgb(255, 255, 255)",
	secondaryContainer: "rgb(255, 217, 226)",
	onSecondaryContainer: "rgb(43, 21, 28)",
	tertiary: "rgb(124, 86, 53)",
	onTertiary: "rgb(255, 255, 255)",
	tertiaryContainer: "rgb(255, 220, 193)",
	onTertiaryContainer: "rgb(46, 21, 0)",
	error: "rgb(186, 26, 26)",
	onError: "rgb(255, 255, 255)",
	errorContainer: "rgb(255, 218, 214)",
	onErrorContainer: "rgb(65, 0, 2)",
	background: "rgb(255, 255, 255)",
	onBackground: "rgb(32, 26, 27)",
	surface: "rgb(255, 251, 255)",
	onSurface: "rgb(32, 26, 27)",
	surfaceVariant: "rgb(242, 221, 225)",
	onSurfaceVariant: "rgb(81, 67, 71)",
	outline: "rgb(131, 115, 119)",
	outlineVariant: "rgb(213, 194, 198)",
	shadow: "rgb(0, 0, 0)",
	scrim: "rgb(0, 0, 0)",
	inverseSurface: "rgb(53, 47, 48)",
	inverseOnSurface: "rgb(250, 238, 239)",
	inversePrimary: "rgb(255, 177, 200)",
	elevation: {
		level0: "transparent",
		level1: "rgb(250, 242, 247)",
		level2: "rgb(247, 236, 242)",
		level3: "rgb(244, 230, 238)",
		level4: "rgb(243, 229, 236)",
		level5: "rgb(241, 225, 233)",
	},
	disabled: "rgba(247, 246, 244, 1)",
	surfaceDisabled: "rgba(32, 26, 27, 0.12)",
	backgroundSurfaceDisabled: "rgba(32, 26, 27, 0.09)",
	onSurfaceDisabled: "rgba(32, 26, 27, 0.38)",
	disableContainer: "rgba(247, 246, 244, 1)",
	onDisableContainer: "rgba(181, 181, 181, 1)",
	backdrop: "rgba(58, 45, 48, 0.4)",
	opacityBackground: "rgba(158, 145, 148, 0.4)",
	onPrimaryOpacity: "rgba(255, 255, 255, 0.4)",
	opacityBackgroundBlack: "rgba(0, 0, 0, 0.4)",
	fullOpacity: "rgba(158, 145, 148, 0)",
	borderOnPrimary: "rgb(117, 117, 117)",
	text: "rgb(0,0,0)",
	borderWidth: "rgba(226, 226, 226, 1)",
	textProfile: "rgba(117, 117, 117, 1)",
	textSecondary: "rgba(117, 117, 117, 1)",
	usernameProfile: "rgba(181, 181, 181, 1)",
	secondaryBackground: "rgba(247, 246, 244, 1)",
	tagQuestion: "rgba(244, 244, 244, 1)",
	logout: "rgba(218, 55, 50, 1)",
	errorMessage: "rgba(218, 55, 50, 1)",
	squadBlue: "rgba(68, 121, 219, 1)",
	squadLightBlue: "rgba(120, 184, 208, 1)",
	squadPink: "rgba(219, 131, 164, 1)",
	squadOlive: "rgba(164, 179, 70, 1)",
	squadGreen: "rgba(66, 148, 74, 1)",
	squadOrange: "rgba(234, 103, 43, 1)",
	squadRed: "rgba(218, 55, 50, 1)",
	squadYellow: "rgba(248, 203, 58, 1)",
};
