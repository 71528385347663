import React from "react";
import "./Modals.css";
import { ReactComponent as CloseIcon } from "../../assets/union_black.svg";

const ListModal = ({ onClose, title = "", items, onItemClick }) => {
	const handleItemClick = (item) => {
		if (onItemClick) {
			onItemClick(item);
		}
	};

	return (
		<div className="modal-overlay">
			<button onClick={onClose}>
				<CloseIcon />
			</button>
			<div className="modal-content">
				<h2>{title}</h2>
				<ul>
					{items.map((item, index) => (
						<li key={index} onClick={() => handleItemClick(index)}>
							{typeof item === "function" ? item() : item}
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default ListModal;
