import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./screens/Home/Home";
import Profile from "./screens/RedirectProfile";
import Question from "./screens/RedirectQuestion";
import Guide from "./screens/RedirectGuide";


import TermsOfService from "./components/TermsOfService/TermsOfService";
import Header from "./components/Header";
import Footer from "./components/Footer";

function Routing() {
	// const id = 3;
	// const value = `/page/${id}`
	return (
		<BrowserRouter>
			<div>
				<Header />
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/termsofservice" element={<TermsOfService />} />
					<Route path="/:id" element={<Profile />} />
					<Route path="/question/:id" element={<Question/>} />
					<Route path="/profile/:id" element={<Profile />} />
					<Route path="/guide/:id" element={<Guide/>} />

				</Routes>
				<Footer />
			</div>
		</BrowserRouter>
	);
}
export default Routing;
