import React from "react";
import { ReactComponent as TikTokLogo } from "../assets/logo-tiktok.svg";

const TikTokButton = ({ username, height, width, fill }) => {
	const redirect = () => {
		const url = `https://www.tiktok.com/@${username}`;
		window.open(url, "_blank");
	};

	return (
		<button
			onClick={redirect}
			style={{
				border: "none",
				background: "transparent",
				cursor: "pointer",
			}}
		>
			<TikTokLogo style={{ width: width, height: height, fill: fill }} />
		</button>
	);
};

export default TikTokButton;
